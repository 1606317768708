import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import '../App.css';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import ShareOnSocial from "react-share-on-social";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '60px',
  margin: '1rem'
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#b2071d',
    marginBottom:'2rem',
    '&:hover': {
      backgroundColor: '#b2071d',
    },
  }));


const darkTheme = createTheme({ palette: { mode: 'dark' } });
export default function GetInvolved() {

  return (
    <div className="App-header">
      <Box>
      <ThemeProvider theme={darkTheme}> 
          <Grid container  spacing={1}>

            <Grid xs={12} md={12}>
             
              <Item key={2} elevation={2}>
                <Typography textAlign="center" sx={{ fontSize: '2rem',color: 'white', opacity:'85%', padding:'2rem'}}>
                    Help us turn this film into a tidal wave of action!
                </Typography>
                <ShareOnSocial
                    textToShare="Check out this film"
                    link="https://www.artclubmovie.com"
                    linkTitle="Art Club Movie"
                    linkMetaDesc="Check out this film"
                    noReferer
                  >
                <ColorButton  variant="contained">
                    <Typography textAlign="center" sx={{ fontFamily: 'Love Ya Like A Sister, cursive',fontSize: '1.3rem' }}>
                    Click Here to share the film
                    </Typography>
                </ColorButton >
                </ShareOnSocial>
              </Item>
            </Grid>

            <Grid xs={12} md={12}>
             
             <Item key={2} elevation={2}>
               <Typography textAlign="center" sx={{ fontSize: '2rem',color: 'white', opacity:'85%', padding:'2rem'}}>
                  We need YOUR help to circulate petitions. Help protect parental rights and female spaces.
                </Typography>
               <ColorButton  variant="contained"  >
                    <Link to="https://www.ProtectKidsColorado.org" target="_blank" rel="noreferrer" style={{ fontSize: '1.5rem', color: 'white',fontFamily: 'Love Ya Like A Sister, cursive'}}>
                        Protect Kids Colorado
                    </Link>
                </ColorButton >
             </Item>
           </Grid>

            <Grid xs={12} md={12}>
             
             <Item key={2} elevation={2}>
               <Typography textAlign="center" sx={{ fontSize: '2rem',color: 'white', opacity:'85%', padding:'1rem'}}>
                   Host a Showing
               </Typography>
               <Typography variant="h6" textAlign="center" sx={{ fontSize: '1rem',color: 'white', opacity:'85%', padding:'1rem'}}>
                   For a public showing at your church, theatre or other meetings, please contact us at ArtClubMovie@gmail.com
               </Typography>
               <Typography variant="h6" textAlign="center" sx={{ fontSize: '1rem',color: 'white', opacity:'85%', paddingBottom:'1rem'}}>
                   We want to help you share this film with your circle of influence in the best way possible.
               </Typography>
             </Item>
           </Grid>
          </Grid>
          </ThemeProvider>
      </Box>
    </div>
  );
}
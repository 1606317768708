import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import '../App.css';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import ShareOnSocial from "react-share-on-social";
import ContactForm from '../components/unsubscribeForm';
import artClubLogo from "..//images/art_club.jpg";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '60px',
  margin: '1rem'
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#b2071d',
    marginBottom:'2rem',
    '&:hover': {
      backgroundColor: '#b2071d',
    },
  }));


const darkTheme = createTheme({ palette: { mode: 'dark' } });
export default function Unsubscribe() {

  return (
    <div className="App-header">
      <Box>
        <ThemeProvider theme={darkTheme}> 
          <Grid container  spacing={1}>
            <Grid xs={12}>
              <img style={{ width:'60%', paddingTop:'1rem'}} src={artClubLogo} alt="" />
            </Grid>
            <Grid xs={12} md={12}>
              <ContactForm/>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Box>
    </div>
  );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar sx={{ backgroundColor: "black" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
    
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="white"
            >
            <MenuIcon sx={{ color: 'white'}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                 display: { xs: 'block', md: 'none' },
                 "& .MuiMenu-paper": { backgroundColor: "black" }
              }}
            >

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={{ color: 'white'}}>
                      Home
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/PayItForward" style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={{ color: 'white'}}>
                      Donate
                  </Typography>
                </Link>
              </MenuItem>  
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/GetInvolved" style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={{ color: 'white'}}>
                      Get Involved
                  </Typography>
                </Link>
              </MenuItem> 
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="https://www.StopGenderIdeology.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={{ color: 'white'}}>
                      Stop Gender Ideology
                  </Typography>
                </Link>
              </MenuItem>         
            </Menu>
          </Box>



          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Home
                </Button>
              </Link>
              <Link to="/PayItForward" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Donate
                </Button>
              </Link>
              <Link to="/GetInvolved" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Get Involved
                </Button>
              </Link>
              <Link to="https://www.StopGenderIdeology.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Stop Gender Ideology
                </Button>
              </Link>

          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

import React, { useState, useRef } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#b2071d',
    margin: '1.3rem',
    '&:hover': {
      backgroundColor: '#b2071d',
    },
  }));

export default function ContactForm() {
  const [email, setEmail] = useState("");
  const form = useRef();
  const [buttonText, setbuttonText] = useState("Submit");
  const [searchParams, setSearchParams] = useSearchParams();
  // Get a specific query parameter
  const id = searchParams.get('id');

  const handleSubmit = (e) => {
    e.preventDefault();

    if(validateEmail(email)){
      Unsubscribe();
    }
    
    setbuttonText("You have been Unsubscribed")
  };

  function Unsubscribe() {
    fetch(`https://artclubmovie-bff.azurewebsites.net/api/v1/users/unsubscribe`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key':'99MSHOEY0PhS88Hnc6D1mpkR9dfeEepOCjdbNL2sVhfcAzFu81UA6Q=='
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        email: email,
        id: id
      })
    })
    .then(data => data.json())
    .then(json => {

    })
    .catch((error) => {

    });;
  }

  const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        return true;
    }
    else {
        return false;
    }
  }

  return (
    <Box
      sx={{
        display: "flex"
      }}
    >
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        <Typography variant="h5" align="center" mb={2}>
          Please fill out the form below to unsubscribe
        </Typography>
        <form ref={form} onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            name="user_email"
          />
          <ColorButton variant="contained" type="submit" sx={{ mt: 2 }}>
            {buttonText}
          </ColorButton>
        </form>
      </Box>
    </Box>
  );
}
import '../App.css';
import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stream } from "@cloudflare/stream-react";
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ShareOnSocial from "react-share-on-social";
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ChipsArray from '../components/chipArray';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";
import ShareFavicon from '../images/logo192.png';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '60px'
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } });

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#b2071d',
  margin: '1.3rem',
  '&:hover': {
    backgroundColor: '#b2071d',
  },
}));

function Main() {
  const videoIdOrSignedUrl = "8d7628fb20beeeb03c4f4ca6d2968244";
  const [ userCanView, setUserCanView ] = useState(false);
  const [ totalViewCount, SetTotalViewCount ] = useState('');
  const [open, setOpen] = useState(false);
  const [userEmail, SetUserEmail] = useState('')
  const [dialogWarning, SetDialogWarning] = useState('')

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    SetDialogWarning('')
  };

  const handleSubscribe = () => {

    if(validateEmail(userEmail)){
      CallAPI(userEmail);
      setOpen(false);
      setUserCanView(true);
    }else{
      SetDialogWarning('Please enter a valid email address')
    }
  };

  useEffect(() => {
    setUserCanView(JSON.parse(window.sessionStorage.getItem("userCanView")));
  }, []);

  useEffect(() => {
    GetViewCount();
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("userCanView", userCanView);
  }, [userCanView]);

  const validateEmail = (email) => {
        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }
  }

  function CallAPI(email) {
    fetch(`https://artclubmovie-bff.azurewebsites.net/api/v1/users`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key':'99MSHOEY0PhS88Hnc6D1mpkR9dfeEepOCjdbNL2sVhfcAzFu81UA6Q=='
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        email: email
      })
    })
    .then(data => data.json())
    .then(json => {
        setUserCanView(true);
    })
    .catch((error) => {

    });;
  }

  function GetViewCount(email) {
    fetch(`https://artclubmovie-bff.azurewebsites.net/api/v1/users/viewcount`, {
      method: "get",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key':'99MSHOEY0PhS88Hnc6D1mpkR9dfeEepOCjdbNL2sVhfcAzFu81UA6Q=='
      }
    })
    .then(data => data.json())
    .then(json => {
      SetTotalViewCount(json["totalViewCount"]);
    })
    .catch((error) => {

    });;
  }

return (
      <div className="App-header">
        <Box>
        <ThemeProvider theme={darkTheme}> 
          <Grid container spacing={1}>        
              <Grid xs={12} md={12}>
                  <Typography textAlign="center" sx={{ fontSize: '6rem', fontFamily: 'Love Ya Like A Sister, cursive', color: 'white'}}>
                    Art Club
                  </Typography>
              </Grid>
              <Grid style={{textalign: "center"}} xs={12} md={12}>

                  <div className="container-video">
                    <div className="responsive-iframe">
                      
                      { 
                        userCanView ? <div></div> : 
                        <div onClick={handleClickOpen} className="overlay">
                          
                        </div>  
                      }

                      <Stream  controls src={videoIdOrSignedUrl} />
                      
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Enter email adress</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            This will unlock the movie for viewing. We'll never share your email.
                          </DialogContentText>
                          <DialogContentText sx={{ color:'red' }}>
                            {dialogWarning}
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            required
                            variant="standard"
                            value={userEmail}
                            onChange={e => {
                              SetUserEmail(e.target.value)
                            }}
                          />
                          <DialogContentText>
                            By clicking Unlock, you agree to our <Link target="_blank" rel="noreferrer" href={'https://www.privacypolicies.com/live/06aa7dcc-b797-461a-8eca-2acb9e0c0939'}>Terms and Conditions</Link>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Cancel</Button>
                          <Button onClick={handleSubscribe}>Unlock</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
              </Grid>
              <Grid xs={12}>
              <Typography textAlign="right" sx={{ fontSize: '.8rem', color: 'white',opacity:'80%'}}>
                {totalViewCount} views
              </Typography>
              </Grid>              
              <Grid xs={12}>  
                <ShareOnSocial
                    textToShare="Check out this film"
                    link="https://www.artclubmovie.com"
                    linkTitle="Art Club Movie"
                    linkMetaDesc="Check out this film"
                    noReferer
                    linkFavicon={ShareFavicon}
                  >
                    <ColorButton  variant="contained">
                      <Typography textAlign="center" sx={{ fontSize: '1.5rem', fontFamily: 'Love Ya Like A Sister, cursive', }}>
                        Click Here to share the film
                      </Typography>
                    </ColorButton >
                </ShareOnSocial>
              </Grid>

              <Grid xs={12} md={12}>
                <Item  key={2} elevation={2}>
                <Typography sx={{ textDecoration: 'underline', paddingTop: '2rem', color: 'white', typography: { sm: 'h4', xs: 'h6' } }} textAlign="center" >
                      About the Film
                  </Typography>
                  <Typography variant="body1"  textAlign="left" sx={{ paddingLeft:'1rem', paddingRight:'1rem',paddingBottom:'1rem', paddingTop:'2rem',color: 'white',opacity:'80%'}}>
                      Kevin Lundberg, along with the Lee family, have spent the last year producing this first-hand account of their family’s experience with public school indoctrination and the transgender social contagion. 
                  </Typography>
                  <Typography variant="body1"  textAlign="left" sx={{ padding:'1rem', color: 'white',opacity:'80%'}}>                    
                      This film takes an intimate look at this important issue, incorporates expert testimony, and offers advice for any parent, grandparent, aunt, uncle or friend to protect the children in their lives and stand up for truth. 
                  </Typography>
                  <Typography variant="body1"  textAlign="left" sx={{ marginBottom:'2rem',paddingLeft:'1rem', paddingRight:'1rem',paddingBottom:'1rem', paddingTop:'1rem', color: 'white',opacity:'80%'}}> 
                      Art Club is a labor of love that was made possible by the support of many people. We’re committed to making this film widely available for free, but we can’t do it alone. Help us turn this project into a tidal wave of action by sharing the film & paying it forward!
                  </Typography>
               
                </Item>
              </Grid>
              <Grid xs={12}>   
                <Typography variant="body1"  textAlign="center" sx={{ paddingLeft:'1rem', paddingRight:'1rem', paddingTop:'1rem', color: 'white',opacity:'80%'}}> 
                    Also available on:
                </Typography>
                <ChipsArray/>
                <Link component={RouterLink} to="/unsubscribe" style={{ fontSize: '1rem', color: 'white'}}>
                  Unsubscribe
                </Link>
              </Grid>
          </Grid>
          </ThemeProvider>
        </Box>
      </div>
  );
}

export default Main;



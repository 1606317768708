import React, { Component } from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Main from './pages/main.js';
import PayItForward from './pages/donate.js';
import SearchAppBar from './pages/navBar.js';
import GetInvolved from './pages/getInvolved.js';
import Unsubscribe from './pages/unsubscribe.js'

class App extends Component {
  render() {
    const App = () => (    
      <div className="App bgimg">
          <SearchAppBar/>
          <Routes>
            <Route path='/' element={ <Main /> } />
            <Route path='/PayItForward' element={ <PayItForward /> } />
            <Route path='/GetInvolved' element={ <GetInvolved /> } />
            <Route path='/unsubscribe' element={ <Unsubscribe /> } />
          </Routes>
      </div>  
    )
    return (
        <App/>
    );
  }
}

export default App;
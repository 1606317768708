import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import '../App.css';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#b2071d',
  margin:'2rem',
  '&:hover': {
    backgroundColor: '#b2071d',
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '60px',
  margin: '1rem'
}));
const darkTheme = createTheme({ palette: { mode: 'dark' } });
export default function Resources() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    SetClickedThrough();
  }, [id]);

  function SetClickedThrough() {
    if(id){
        fetch(`https://artclubmovie-bff.azurewebsites.net/api/v1/email/open/${id}`, {
          method: "get",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-functions-key':'99MSHOEY0PhS88Hnc6D1mpkR9dfeEepOCjdbNL2sVhfcAzFu81UA6Q=='
          },
        })
        .then(data => data.json())
        .then(json => {

        })
        .catch((error) => {

        });;
      }
  }

  return (
    <div className="App-header">
      <Box>
      <ThemeProvider theme={darkTheme}> 
          <Grid container  spacing={1}>
            <Grid xs={12} md={12}>
             
              <Item key={2} elevation={2}>
                <Typography variant="h6" textAlign="center" sx={{ fontSize: '1.3rem',color: 'white', opacity:'85%', padding:'2rem'}}>
                    Our mission is to make this film widely available for free. We want our story to help as many people as possible, but distribution costs money. We welcome your support in sharing this documentary and bringing its message to action! 
                </Typography>
              </Item>

           
            </Grid>
            <Grid xs={12} md={12}>
              <Item key={2} elevation={2}>
                <ColorButton  variant="contained">
                  
                    <Link to="https://www.givesendgo.com/ArtClubMovie" target="_blank" rel="noreferrer" style={{ fontSize: '1.5rem', color: 'white',fontFamily: 'Love Ya Like A Sister, cursive'}}>
                      Click here to donate to our Give Send Go
                    </Link>
                  
                </ColorButton>
                </Item>
            </Grid>
            <Grid xs={12} md={12}>
              <Item key={2} elevation={2}>
                <ColorButton  variant="contained">
                  
                    <Link to="https://www.venmo.com/u/StopGenderIdeology" target="_blank" rel="noreferrer" style={{ fontSize: '1.5rem', color: 'white',fontFamily: 'Love Ya Like A Sister, cursive'}}>
                      Click here to donate to our Venmo
                    </Link>
                  
                </ColorButton>
                </Item>
            </Grid>
            <Grid xs={12} md={12}>   
            <Item key={2} elevation={2}>
              <Typography textAlign="center" sx={{ paddingTop: '2rem', opacity:'85%', color: 'white', typography: { sm: 'h4', xs: 'h6' } }}>
                  Cash & Checks payable to:
              </Typography>
              <Typography textAlign="center" sx={{ color: 'white', opacity:'85%'}}>
                  Stop Gender Ideology
              </Typography>
              <Typography textAlign="center" sx={{ color: 'white', opacity:'85%'}}>
                  P.O. Box 1501
              </Typography>
              <Typography textAlign="center" sx={{ paddingBottom: '2rem', color: 'white', opacity:'85%'}}>
                  Wellington, CO 80549
              </Typography>
              </Item>      
            </Grid>
          </Grid>
          </ThemeProvider>
      </Box>
    </div>
  );
}